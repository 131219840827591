const EducationIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="64px"
      height="64px"
      viewBox="0 0 48.00 48.00"
      id="Layer_1"
      xml-space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns-xlink="http://www.w3.org/1999/xlink"
      stroke="#1b2444"
      fill="#1b2444"
      strokeWidth="0.00048000000000000007"
    >
      <path d="M41,16.764h-1.47v-2.48c0-0.235-0.164-0.438-0.394-0.488l-2.29-0.5c-3.223-0.705-6.64-0.743-9.884-0.112 l-2.993,0.591l-2.955-0.581c-3.28-0.637-6.712-0.597-9.922,0.112l-2.23,0.49c-0.229,0.05-0.393,0.253-0.393,0.488v2.48H7 c-0.276,0-0.5,0.224-0.5,0.5v17.5c0,0.276,0.224,0.5,0.5,0.5h34c0.276,0,0.5-0.224,0.5-0.5v-17.5 C41.5,16.988,41.276,16.764,41,16.764z M9.47,14.686l1.838-0.404c3.079-0.68,6.371-0.717,9.516-0.107l3.05,0.6 c0.064,0.013,0.129,0.013,0.193,0l3.089-0.61c3.109-0.604,6.388-0.567,9.479,0.107l1.896,0.415v2.578v13.373l-1.909-0.403 c-0.81-0.169-1.632-0.296-2.458-0.382c-2.479-0.259-5.006-0.145-7.422,0.342l-2.742,0.55l-2.801-0.55 c-0.4-0.081-0.802-0.15-1.207-0.21c-0.103-0.015-0.207-0.024-0.31-0.038c-0.302-0.041-0.604-0.081-0.907-0.11 c-0.138-0.013-0.277-0.02-0.416-0.031c-0.27-0.022-0.54-0.044-0.811-0.057c-0.153-0.007-0.307-0.007-0.461-0.011 c-0.233-0.007-0.466-0.017-0.699-0.017c-0.023,0-0.046,0.002-0.069,0.002c-0.215,0.001-0.43,0.01-0.645,0.017 c-0.206,0.006-0.413,0.008-0.619,0.019c-0.217,0.012-0.433,0.033-0.649,0.05c-0.203,0.016-0.406,0.028-0.608,0.049 c-0.234,0.024-0.465,0.059-0.698,0.091c-0.183,0.025-0.368,0.044-0.55,0.073c-0.413,0.065-0.824,0.14-1.232,0.227L9.47,30.637 V17.264V14.686z M40.5,34.264h-33v-16.5h0.97v13.49c0,0.151,0.068,0.293,0.186,0.389c0.117,0.095,0.271,0.134,0.418,0.101l2.45-0.52 c3.104-0.657,6.38-0.672,9.479-0.048l2.9,0.57c0.065,0.013,0.13,0.012,0.195,0l2.84-0.57c3.089-0.622,6.367-0.609,9.478,0.039 l2.511,0.53c0.034,0.007,0.069,0.011,0.104,0.011c0.027,0,0.052-0.009,0.079-0.013c0.019-0.003,0.038-0.004,0.057-0.01 c0.064-0.018,0.126-0.045,0.18-0.089c0.116-0.095,0.185-0.237,0.185-0.388v-13.49h0.97V34.264z"></path>

      <path d="M12,20.702h4.25c0.276,0,0.5-0.224,0.5-0.5v-4.25c0-0.276-0.224-0.5-0.5-0.5H12c-0.276,0-0.5,0.224-0.5,0.5 v4.25C11.5,20.478,11.724,20.702,12,20.702z M12.5,16.452h3.25v3.25H12.5V16.452z"></path>
      <path d="M31.75,20.702H36c0.276,0,0.5-0.224,0.5-0.5v-4.25c0-0.276-0.224-0.5-0.5-0.5h-4.25c-0.276,0-0.5,0.224-0.5,0.5 v4.25C31.25,20.478,31.474,20.702,31.75,20.702z M32.25,16.452h3.25v3.25h-3.25V16.452z"></path>
      <path d="M13.915,22.118l-1.984,0.276c-0.273,0.038-0.464,0.291-0.426,0.564c0.038,0.274,0.295,0.461,0.564,0.426 l1.984-0.276c1.705-0.237,3.438-0.237,5.143,0l1.984,0.276c0.023,0.003,0.047,0.005,0.07,0.005c0.245,0,0.459-0.181,0.494-0.431 c0.038-0.273-0.152-0.526-0.426-0.564l-1.984-0.276C17.536,21.867,15.714,21.867,13.915,22.118z"></path>
      <path d="M21.337,25.521l-1.313-0.231c-2.256-0.396-4.543-0.396-6.799,0l-1.313,0.231 c-0.271,0.048-0.453,0.307-0.405,0.579c0.047,0.272,0.31,0.451,0.579,0.406l1.313-0.231c2.141-0.376,4.311-0.375,6.451,0 l1.313,0.231c0.029,0.005,0.059,0.007,0.088,0.007c0.237,0,0.449-0.171,0.491-0.414C21.79,25.829,21.608,25.57,21.337,25.521z"></path>
      <path d="M26.819,23.384l1.984-0.276c1.705-0.237,3.438-0.237,5.143,0l1.984,0.276c0.023,0.003,0.047,0.005,0.07,0.005 c0.245,0,0.459-0.181,0.494-0.431c0.038-0.273-0.152-0.526-0.426-0.564l-1.984-0.276c-1.799-0.251-3.621-0.251-5.42,0l-1.984,0.276 c-0.273,0.038-0.464,0.291-0.426,0.564C26.293,23.232,26.548,23.419,26.819,23.384z"></path>
      <path d="M36.087,25.521l-1.313-0.231c-2.256-0.396-4.543-0.396-6.799,0l-1.313,0.231 c-0.271,0.048-0.453,0.307-0.405,0.579c0.047,0.272,0.312,0.451,0.579,0.406l1.313-0.231c2.141-0.376,4.311-0.375,6.451,0 l1.313,0.231c0.029,0.005,0.059,0.007,0.088,0.007c0.237,0,0.449-0.171,0.491-0.414C36.54,25.829,36.358,25.57,36.087,25.521z"></path>
    </svg>
  );
};

export default EducationIcon;
