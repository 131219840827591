import ContactIcons from "./ContactIcons";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <ContactIcons />
    </div>
  );
};

export default Footer;
