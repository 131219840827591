const WorkIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="64px"
      height="64px"
      viewBox="-6 -6 48.00 48.00"
      id="Layer_1"
      xml-space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns-xlink="http://www.w3.org/1999/xlink"
      stroke="#1b2444"
      fill="#1b2444"
      strokeWidth="0.00048000000000000007"
    >
      <path d="M20,12.5L20,12.5c0,0.276,0.224,0.5,0.5,0.5h7c0.276,0,0.5-0.224,0.5-0.5v0c0-0.276-0.224-0.5-0.5-0.5 h-7C20.224,12,20,12.224,20,12.5z M20.5,15h7c0.276,0,0.5-0.224,0.5-0.5v0c0-0.276-0.224-0.5-0.5-0.5h-7c-0.276,0-0.5,0.224-0.5,0.5 v0C20,14.776,20.224,15,20.5,15z M20.5,17h7c0.276,0,0.5-0.224,0.5-0.5l0,0c0-0.276-0.224-0.5-0.5-0.5h-7 c-0.276,0-0.5,0.224-0.5,0.5l0,0C20,16.776,20.224,17,20.5,17z M20.5,19h7c0.276,0,0.5-0.224,0.5-0.5l0,0c0-0.276-0.224-0.5-0.5-0.5 h-7c-0.276,0-0.5,0.224-0.5,0.5l0,0C20,18.776,20.224,19,20.5,19z M16,18v-6c0-0.552-0.448-1-1-1h-1c-0.552,0-1,0.448-1,1v6h-1v-4 c0-0.552-0.448-1-1-1h-1c-0.552,0-1,0.448-1,1v4H8v-5c0-0.552-0.448-1-1-1H6c-0.552,0-1,0.448-1,1v5H4.5C4.224,18,4,18.224,4,18.5 l0,0C4,18.776,4.224,19,4.5,19h12c0.276,0,0.5-0.224,0.5-0.5l0,0c0-0.276-0.224-0.5-0.5-0.5H16z M7,18H6v-5h1V18z M11,18h-1v-4h1V18 z M15,18h-1v-6h1V18z M29,4H3C1.343,4,0,5.343,0,7v16c0,1.657,1.343,3,3,3h9v3h-1.5c-0.276,0-0.5,0.224-0.5,0.5l0,0 c0,0.276,0.224,0.5,0.5,0.5h11c0.276,0,0.5-0.224,0.5-0.5l0,0c0-0.276-0.224-0.5-0.5-0.5H20v-3h9c1.657,0,3-1.343,3-3V7 C32,5.343,30.657,4,29,4z M19,29h-6v-3h6V29z M31,23c0,1.105-0.895,2-2,2H3c-1.105,0-2-0.895-2-2V7c0-1.105,0.895-2,2-2h26 c1.105,0,2,0.895,2,2V23z"></path>
    </svg>
  );
};

export default WorkIcon;
